
import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import './Accordion.css';
import { mq } from "../utils/media-queries"


import treatmentTable from '../assets/images/treatmentTable.png'

const ReverseAccordion = ({ title, body }) => {


  const content = useRef(null);
  const [setActive, setActiveState] = useState("active");
  const [setHeight, setHeightState] = useState("fit-content");
  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <Container
      css={css` 
        margin-top: 20px;
        h3 {
          margin: 0px;
          color: #8C8D91;
          overflow: hidden;
          font: bold 18px/20px Arial, sans-serif;
        }
        a {
          color: #01AEF1;
        }
        p {
          font: 18px/22px Arial, sans-serif;
          color: #8C8D91;
          padding-bottom: 15px;
        }
        .accwrapper {
          display: flex;
          flex-direction: column;        
        }
        .accordion {
          cursor: pointer;
          padding: 15px 53px 15px 0;
          width: 100%;
          text-align: left;
          border: none;
          outline: none;
          transition: 0.4s;
        }
        .active, .accordion:hover {
          color: #AE58CF;
        }
        .accbody {
          padding: 7px 53px 0px;
        }
        .acccontent {
          background-color: white;
          overflow: hidden;
          transition: max-height 0.6s ease;
        }

        ${[mq[2]]} {
          h2 {
            font-size: 20px;
            line-height: 21px;
          }
          p {
              font-size: 14px;
              line-height: 20px;
          }
        }
      `}
    >
      <Row>
        <Col
          css={css`
          padding: 0 15px 0px;
          `}>
          <div className="accwrapper">
            <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
              <h3>
                {title}
              </h3>
            </div>
            <div ref={content}
              style={{ height: 'auto', maxHeight: `${setHeight}` }}
              className="acccontent"
            >
              <div className="accbody" dangerouslySetInnerHTML={{ __html: body }}>
              </div>
              {title === "How do I evaluate my Hodgkin lymphoma treatment options?" ? (<img css={css`
                    display: block;
                    margin: 0 auto;
                    ${[mq[2]]} {
                      width: 350px;
                    }
                  `}
                src={treatmentTable}
                alt='treatment table'
              />) : null}

            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ReverseAccordion
