import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"

import questionsPdf from "../assets/downloadables/HHub-DiscussionGuide_DownloadPDF.pdf"
import downloadArrow from '../assets/images/doctor-discussion-guide-arrow-down.svg'

const DiscussionGuideDownload = () => {
  return (
    <Container
      css={css`
        margin-top: 25px;
        padding: 30px 15px 30px;
        max-width: 1110px;
        background-image: linear-gradient(to bottom,#58d0ff,#01aef1);
        h3 {
            color: #FFF;
            font: bold 24px/28px Arial, sans-serif;
        }
        a {
            &:hover {
              text-decoration: none;
            }
          }
        p {
            margin-top: 25px;
            color: #FFF;
            font-size: 18px;
            line-height: 25px;
        }
        ${[mq[2]]} {
            margin-bottom: 20px;
            h3 {
                margin-top: 15px;
                font-size: 18px;
                line-height: 21px;
            }
        }
      `}
    >
      <Row css={css`
            ${[mq[2]]} {
                flex-direction: column-reverse;
            }
          `}>
        <Col
          css={css`
            text-align: center;
            border-right: 1px solid #FFF;
          `}>
          <p>
            To print additional copies of this guide, <br /> please click download
          </p>
        </Col>
        <Col
          lg={6}
        >
          <a
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              padding: 0 15px;
            ${[mq[2]]} {
                flex-direction: row;
                margin: auto;
                padding-bottom: 20px;
                width: 190px;
                border-bottom: 1px solid #FFF;
            }
            `}
            href={questionsPdf}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>DOWNLOAD</h3>
            <img
              loading='lazy'
              src={downloadArrow}
              alt=''
              css={css`
                    ${[mq[2]]} {
                    width: 39px;
                    height: 39px;
                    }
                `}
            />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default DiscussionGuideDownload
