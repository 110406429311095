import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
const DiscussionGuideHeader = () => {
  return (
    <Container
      css={css`
        margin-top: 30px;
        h1 {
          margin-bottom: 25px;
          padding-top: 25px;
          color: #69696A;
          font: bold 45px/46px Arial, sans-serif;
          text-align: center;
          text-transform: uppercase;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
        p {
          font: 18px/22px Arial, sans-serif;
          color: #8C8D91;
          padding-bottom: 15px;
        }
        ${[mq[2]]} {
          h1 {
            font-size: 20px;
            line-height: 21px;
          }
          p {
              font-size: 14px;
              line-height: 20px;
          }
        }
      `}
    >
      <Row>
        <Col  
          css={css`
          padding: 0 15px 20px;
          `}>
        <h1>Hodgkin Lymphoma Treatment <br/> Discussion Guide</h1>
        <p>
            After a Hodgkin lymphoma diagnosis, it’s important to be able to advocate for your health, which includes preparing questions you want to ask your healthcare provider (HCP) about your recommended treatment plan. If you do not understand the information your HCP gives you, ask for it to be explained in a different way. Taking an active role during these conversations will help you feel confident to make a treatment decision that is right for you.
        </p>
        <div css={css`
            height: 12px;
            background: linear-gradient(180deg, #AE58CF 0%, #7E3F97 100%);
          `}>
        </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DiscussionGuideHeader
