import React from 'react'
import Layout from '../components/Layout'

import DiscussionGuideHeader from '../components/DiscussionGuideHeader'

import Accordion from '../components/Accordion'
import ReverseAccordion from '../components/ReverseAccordion'
import DiscussionGuideDownload from '../components/DiscussionGuideDownload'
import DiscussionGuideDivider from '../components/DiscussionGuideDivider'
import References from '../components/about/References'


export default () => {

  return (
    <Layout
      title='Hodgkin Lymphoma Treatment Decisions Guide | Hodgkin Hub'
      description='Read or download a PDF guide of questions to ask your doctor about Hodgkin Lymphoma and its treatment.'
      canonicalURL='https://hodgkinhub.com/hodgkin-lymphoma-treatment-discussion-guide/'
      OGTitle='Hodgkin Lymphoma Treatment Discussion Guide'
      OGDescription='Read or download a PDF guide of questions to ask your doctor about Hodgkin Lymphoma and its treatment.'
      OGImage='https://hodgkinhub.com/static/header-logo-02c93b151e6a0b1d545c321f517bafd0.png'
      OGUrl='https://hodgkinhub.com/Hodgkin-Lymphoma-treatment-discussion-guide/'
      OGType='Website'
    >
      <DiscussionGuideHeader />
      <ReverseAccordion
        title="What exactly is my Hodgkin lymphoma diagnosis?"
        body="<p>It is important to know the type of Hodgkin lymphoma you have because it will help determine your treatment options. There are two types of Hodgkin lymphoma: classical Hodgkin lymphoma, which is the more common type, and Nodular lymphocyte-predominant Hodgkin lymphoma. Classical Hodgkin lymphoma is further divided into four subtypes: Nodular Sclerosis, Mixed Cellularity, Lymphocyte-rich and Lymphocyte-depleted.<sup>1</sup></p>"
      />
      <Accordion
        title="What stage is my Hodgkin lymphoma?"
        body="<p>Hodgkin lymphoma is diagnosed by stage. The stages — 1, 2, 3, and 4 — are determined by how much the disease has spread from a single lymph node region to the rest of the body. Your doctor will know how far the Hodgkin lymphoma has progressed through imaging scans, physical exams and other tests.<sup>2</sup></p>"
      />
      <Accordion
        title="What tests are administered to diagnose Hodgkin lymphoma?"
        body="<p>Doctors use many tests to find, or diagnose, Hodgkin lymphoma and also whether it has spread to another part of the body. Doctors may also do tests to learn which treatments could work best.</p>
        <p>At a physical exam, your doctor checks for swollen lymph nodes, including in your neck, underarm and groin, as well as a swollen spleen or liver.<sup>3</sup></p>
        <p>Blood tests are conducted to see if anything in your blood indicates the possibility of cancer. Imaging tests, such as X-ray, CT and positron emission tomography (PET), may also be conducted to look for signs of Hodgkin lymphoma in other areas of your body.<sup>4</sup></p>
        <p>Your doctor may recommend a lymph node biopsy procedure to remove a lymph node for laboratory testing. He/she will diagnose classical Hodgkin lymphoma if abnormal cells (called Reed-Sternberg cells) are found within the lymph node. In addition, a bone marrow biopsy and aspiration procedure will involve inserting a needle into your hipbone to remove a sample of bone marrow. The sample is analyzed to look for Hodgkin lymphoma cells.<sup>5</sup></p>"
      />
      <Accordion
        title="What are the different stages of Hodgkin lymphoma?"
        body="<p>Knowing the stage of your Hodgkin lymphoma can help determine how serious it is and how best to treat it.<sup>6</sup></p>
        <p> <span style='font-weight: bold;'><span style='color: #01AEF1;'>Stage 1:</span> Early-stage Hodgkin lymphoma </span><br>
        Hodgkin lymphoma cells are found in a single lymph node region, or in one organ or site outside the lymphatic system.<sup>6</sup></p>
        <p><span style='font-weight: bold;'><span style='color: #01AEF1;'>Stage 2:</span> Early or Advanced Stage Hodgkin lymphoma depending on individual factors </span><br>
        The cancer is in two lymph node regions or the cancer has invaded one organ and the nearby lymph nodes; however, the cancer is still limited to a section of the body either above or below the diaphragm.<sup>6</sup></p>
        <p><span style='font-weight: bold;'><span style='color: #01AEF1;'>Stage 3:</span> Advanced-stage Hodgkin lymphoma</span> <br>
        Advanced-stage Hodgkin lymphoma occurs when the cancer moves to lymph nodes both above and below the diaphragm. Cancer may also be in one portion of tissue or an organ near the lymph node groups or in the spleen.<sup>6</sup></p>
        <p><span style='font-weight: bold;'><span style='color: #01AEF1;'>Stage 4:</span> Advanced-stage Hodgkin lymphoma</span> <br>
        Cancer cells are in several portions of one or more organs and tissues. Stage 4 Hodgkin lymphoma affects not only the lymph nodes but also other parts of the body, such as the liver, lungs or bones.<sup>6</sup></p>"
      />
      <Accordion
        title="What are all the treatment options available to me?"
        body="<p>Because there is no ‘one size fits all’ when it comes to Hodgkin lymphoma treatment, be sure to discuss with your doctor how your diagnosis, medical history, lifestyle and plans for the future will affect your treatment decisions. All treatments come with benefits and risks, so it’s important to speak up and share your questions and concerns about your available treatments. Seek a second opinion to ensure you have a full picture of all your options.<sup>5</sup></p>
        <p>Potential treatment options can also include participation in clinical trials. Talk to your doctor to see if this could be an option for you.</p>"
      />
      <Accordion
        title="How do I evaluate my Hodgkin lymphoma treatment options?"
        body="<p>Your oncologist and care team should work with you to make an informed treatment decision that aligns with your goals, keeping in mind the type of Hodgkin lymphoma you have, the stage, what treatment options are available and how likely these treatments are to work under these circumstances.</p>
        <p>When you have answers to all your questions from your oncologist and through supplementary resources, you can rate the treatments you're considering based on the pros and cons of each, such as effectiveness, side effects, quality of life, financial cost, long term implications, etc.</p>
        <p><span style='color: #01AEF1;'>See below for a sample chart</span> you can utilize to compare treatment options.</p>
        
        "
      />
      <Accordion
        title="What should I be looking for in a cancer treatment center, oncologist and care team?"
        body="<p>Hodgkin lymphoma, like all cancers, requires specialized treatment. After your diagnosis, you will need to choose the team who will manage your cancer care. It’s important to find a treatment center and providers who help you learn about your treatment options, answer all your questions and allow you to partner with them in treatment decision making.<sup>7</sup></p>
        <p>Your oncologist and members of your care team should also be able to help you navigate the emotional impact of Hodgkin lymphoma on you and your loved ones, directing you to resources or advocacy organizations that provide patient support through treatment.<sup>7</sup></p>
        <p>It’s possible that your initial care team may not be a good fit for you and your personal treatment goals. If you feel like your visits are rushed, or your providers aren’t listening to you or answering your questions sufficiently, there are often ways to make things better. Try to be as honest and open as you can about your concerns and see if you are able to work through them with your team.<sup>7</sup></p>
        <p>If you are unable to build a trusting relationship with your oncologist and care team, you may want to consider finding a new doctor or treatment center that feels right for you.<sup>8</sup></p>"
      />
      <Accordion
        title="Why is it important for me to take an active role in my treatment decision?"
        body="<p>Each Hodgkin lymphoma treatment plan is individualized based on your prognosis, stage and goals. Your doctor and care team will help inform you about your treatment options, but since there is a lot of information about Hodgkin lymphoma treatments available from various resources, it’s important to ask questions and collaborate with your doctor and care team on what will be the best fit for you.<sup>8</sup></p>"
      />
      <Accordion
        title="What should I do if I’m struggling to advocate for myself during treatment decision-making?"
        body="<p>Effective communication with your doctor is the best way to make sure you're getting the information you need to make an informed treatment decision.</p>
        <p>To make communicating with your doctor easier, consider writing down your specific questions in advance, recording your conversations, keeping copies of your medical records and trying to speak up when you don’t understand something your doctor may be telling you. If you feel comfortable sharing your medical information with a friend or family member, bring them along to your appointments to take notes or ask questions on your behalf.<sup>8</sup></p>"
      />
      <Accordion
        title="What resources can I use to help make an informed treatment decision?"
        body="<p>Many advocacy organizations offer programs and services to help you throughout your diagnosis, treatment decision making and during treatment. Having open conversations with your doctor and care team will allow them to share tailored resources that will help you become knowledgeable and confident about your treatment options.</p>
        <p>You can also find resources on <a href='/'>Hodgkin Hub</a>, <a class='external-link-class' href='https://www.lls.org/'>The Leukemia & Lymphoma Society</a>, <a class='external-link-class' href='https://www.cancer.net/'>Cancer.Net</a> and <a class='external-link-class' href='https://lymphoma.org/'>Lymphoma Research Foundation</a> to help you navigate treatment decision making.</p>"
      />
      <Accordion
        title="Should I seek a second opinion before starting treatment?"
        body="<p>When you're facing a Hodgkin lymphoma treatment decision, it is normal to look to several different experts to seek opinions on the treatment option that works best for you. You might want to seek a second opinion through another doctor, for example, who can look at your test results, talk with you about your personal situation and give you a new or validated perspective on treatment options. Getting a second opinion can also help you feel confident about your diagnosis and treatment plan. Don’t be afraid to ask your doctor if there is time to get one, and remember you always have the right to a second opinion.<sup>9</sup></p>
        <p>Getting a second opinion is very normal following a cancer diagnosis, and an experienced oncologist should welcome and encourage you to seek a second opinion as you decide on a treatment that is the best fit for you.</p>
        <p>If the second opinion turns out to be different from the first, you may want to consider asking both doctors to explain how they arrived at their recommended treatment plan based on your specific diagnosis. You can also inquire about the clinical data or professional guidelines they consulted, and whether they had recommended this treatment plan successfully to other Hodgkin lymphoma patients with similar cases and considerations as you.<sup>9</sup></p>
        <p>If these questions do not help you come to a treatment decision, ask if it is possible for the two doctors to review your case together.  In some instances, your second opinion may feel like the better choice, and you may want to change your oncologist or treatment center as a result. Most importantly, you should feel confident as you make your decision and prepare to start treatment.<sup>9</sup></p>"
      />
      <Accordion
        title="What factors are important when determining treatment?"
        body="<p>The goals of your treatment may be different from that of someone else. It’s important to consider factors such as effectiveness, relapse rates and side effects, as well as to understand if your treatment goal is cure, keep the disease from progressing, or to make you more comfortable.<sup>10</sup></p>
        <p>The type of health insurance you have may also affect your treatment decision. Ask your insurance company for a list of all in-network and out-of-network treatment centers and providers in your area, what out of pocket costs you may incur and what may be covered if you have private insurance, or Medicaid and/or Medicare. You can also inquire about financial assistance with treatment costs that are offered by pharmaceutical companies who manufacture different treatment options.<sup>11</sup></p>"
      />
      <Accordion
        title="What are the benefits and risks of each treatment option?"
        body="<p>Each treatment comes with benefits and risks, which is why it’s important to understand all your available options. Risks can include short-term side effects, long-term effects and late effects, which may appear months or years after treatment. Balancing those risks with the potential benefit of each treatment can help you and your doctor decide on a personalized treatment plan.<sup>10</sup></p>"
      />
      <Accordion
        title="What are the associated short-term and long-term effects of each option?"
        body="<p>Each treatment option can have potential risks you should be aware of. Side effects are often short-term and occur during treatment, while some might not show up until long after treatment has ended. Such long-term effects may last after treatment is over but resolve over time, while others may improve but remain permanent. It’s important to know the potential short- and long-term effects and understand how they will affect your present and future lifestyle.<sup>12</sup></p>"
      />
      <Accordion
        title="What are some resources I can use to understand my diagnosis and treatment options?"
        body="<p>You should speak with your care team, who can give you verified and appropriate resources for your specific Hodgkin lymphoma diagnosis and potential treatment options. In addition, organizations such as the <a class='external-link-class' href='https://www.lls.org/'>Leukemia and Lymphoma Society</a>, the <a class='external-link-class' href='https://www.cancer.org/'>American Cancer Society</a> and the <a class='external-link-class' href='https://www.cancer.net/'>American Society of Clinical Oncology’s Cancer.Net</a> also have vetted resources for patients.<sup>13</sup></p>"
      />
      <Accordion
        title="How long will I need to stay on my treatment?"
        body="<p>The length of your Hodgkin lymphoma treatment is determined by a variety of factors, including the stage, the types of treatment that are given and your personal experience during treatment. Your oncologist and care team should help to set expectations around the duration of your treatment.<sup>14</sup></p>"
      />
      <Accordion
        title="Will I be in the hospital or an outpatient during my treatment?"
        body="<p>Most often, treatment is given on an outpatient basis in a hospital or clinic. However, there may be times when your symptoms or the side effects of treatment require a stay in the hospital.<sup>15</sup></p>"
      />
      <Accordion
        title="Will I be able to work or attend school during my treatment?"
        body="<p>Having Hodgkin lymphoma does not necessarily mean you will need to stop working or attending school, but you may have to take time off for appointments, treatments or require extra rest. Partnering with your doctor and care team to make informed decisions about your goals during treatment can help you feel more confident as you navigate your Hodgkin lymphoma experience.</p>"
      />
      <Accordion
        title="What kind of testing will be done to monitor my Hodgkin lymphoma and treatment? How often will testing be needed?"
        body="<p>When going through treatment for your Hodgkin lymphoma, you may get regular screenings through blood tests, X-rays and other imaging tests, such as <a class='external-link-class' href='https://www.mayoclinic.org/tests-procedures/pet-scan/about/pac-20385078'>positron emission tomography (PET) scans</a>, <a class='external-link-class' href='https://www.cancer.net/navigating-cancer-care/diagnosing-cancer/tests-and-procedures/bone-scan'>bone scans</a>, <a class='external-link-class' href='https://www.cancer.net/navigating-cancer-care/diagnosing-cancer/tests-and-procedures/electrocardiogram-ekg-and-echocardiogram'>echocardiograms</a> and <a class='external-link-class' href='https://www.cancercenter.com/cancer-types/lung-cancer/diagnosis-and-detection/pulmonary-function-tests'>pulmonary function tests (PFTs).</a> These tests will help your oncologist determine the cadence of your screenings and if your treatment has slowed or stopped your Hodgkin lymphoma.<sup>4</sup></p>"
      />
      <Accordion
        title="How will I know if my treatment is effective? What options are available if my treatment is not effective?"
        body="<p>When deciding on a treatment for your Hodgkin lymphoma, discuss your treatment goals with your oncologist. Throughout treatment, you will see your oncologist for regular follow-up exams, which may continue for a few months or years after your treatment ends.</p>
        <p>Each person reacts to Hodgkin lymphoma treatment differently. Keeping a tracker of how you’re responding to treatment can help you identify signs of short- and long-term effects, which you can provide to your oncologist and care team. Your doctor can help evaluate them with you and suggest management strategies to address changes you may be experiencing.</p>
        <p>For many people with Hodgkin lymphoma, treatment can lead to remission. Your doctor and care team can help set expectations for what success with treatment looks like for you.<sup>16,17</sup></p>"
      />
      <Accordion
        title="How will treatment impact my fertility?"
        body="<p>Some Hodgkin lymphoma treatment options can impact fertility for both women and men. These effects might be temporary or permanent. The likelihood that Hodgkin lymphoma treatment will harm your fertility depends on the type of treatment, the doses given and your age at the time of treatment. When deciding on a treatment, it may be helpful to talk to your doctor and a fertility specialist about fertility preservation options.<sup>5</sup></p>"
      />
      <Accordion
        title="Who is the best person or group to speak to about the emotions I’m feeling?"
        body="<p>Hodgkin lymphoma can also affect your emotional health. Common feelings during this experience can include anxiety, distress and depression.<sup>18</sup> It's important to recognize these changes and seek help when needed. Talk to your health care provider about resources or specialists to help you navigate your mental health needs.</p>"
      />
      <DiscussionGuideDivider />
      <DiscussionGuideDownload />
      <References isDiscussionGuide />
    </Layout>
  )
}