import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

const DiscussionGuideDivider = () => {
  return (
    <Container
      css={css`
        margin-top: 30px;
      `}
    >
      <Row>
        <Col
          css={css`
          padding: 0 15px 15px;
          `}>
          <div css={css`
                height: 12px;
                background: linear-gradient(180deg, #AE58CF 0%, #7E3F97 100%);
            `}>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DiscussionGuideDivider
